<template>
  <div class="editLogo">
    <div class="container pt-5">
      <h1 class="text-center title-mobile pb-2">{{$t('editLogoHeader')}}</h1>
    </div>
    <form id="app" @submit.prevent="handleSubmit">
        <div class="container-fluid pb-4 pt-3 container-edit container-mobile">
          <div class="row col-12 d-flex justify-content-center">
            <div class="col-xl-6 col-lg-8 col-xs-12 d-lg-flex d-xl-flex">
              <div class="col-xl-8 col-lg-9 col-xs-12">
                <div class="form-group input-bar">
                  <!-- <select class="form-control bar-select" v-model="selectedLogo" name="logo" id="logo" :selected="undefined">
                    <option :value="undefined" hidden disabled>Choisir un logo</option>
                    <option v-for="(logo, id) in logos" :key="id" v-bind:value="id" >{{logo.brandname}}</option>
                  </select> -->
                  <b-form-input class="form-control bar-select" list="list-logo" v-model="selectedLogo" id="logo" :placeholder="$t('logoChoosePlaceHolder')"></b-form-input>
                  <b-form-datalist id="list-logo" :options="logos" text-field="brandname" value-field="brandname"></b-form-datalist>
                </div>
              </div>
              <div class="col-xl-4 col-lg-2 col-xs-12 d-flex justify-content-center">
                <div class="form-group col-xs-12 input-bar">
                    <button type="submit" v-on:click.prevent="filter" class="btn btn-dark button-bar pr-3">{{$t('select')}} <i class="fas fa-arrow-right ml-2"></i></button>
                </div>
              </div>
            </div>
            <div v-show="show" class="col-xs-12 show">
                <button type="submit" class="btn edit-btn btn-primary pr-3">{{$t('edit')}} <i class="fas fa-pencil-alt ml-2"></i></button>
              </div>
          </div>
        </div>
        <div v-if="!show" class="container container-edit-large pt-5 pb-5">
          <div class="row col-12 container-emote">
            <div class="col-xl-6 col-lg-4 col-xs-12 text-center">
              <h1 class="title-nothing pt-5"><span class="emote-title-duo">1</span></h1>
              <p class="emote-text pt-4">{{$t('logoChooseFromListLabel')}}</p>
            </div>
            <div class="col-xl-6 col-lg-4 col-xs-12 text-center">
              <h1 class="title-nothing pt-5"><span class="emote-title-less">2</span></h1>
              <p class="emote-text pt-4">{{$t('logoStartSearch')}}</p>
            </div>
          </div>
        </div>
        <div v-show="show" class="container-fluid pt-2 pl-5 container-mobile-content show">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-12 col-lg-12 col-xs-12 container-mobile-content pt-3">
              <div class="row col-12 pt-5 pl-3 d-flex justify-content-center align-items-center">
                <img class="round" width="300px" height="auto" :src="''">
              </div>
              <div class="row col-12 pt-5 d-flex justify-content-center align-items-center">
                <div class="form-group">
                  <small class="form-text text-muted pl-4 pb-1">{{$t('logoImageSizeInfo')}}</small>
                  <input type="file" class="input-file" @change="onFileSelected" name="image" id="image">
                  <div class="form-error pl-4"></div>
                  <p class="file-label">{{$t('insertImage')}} <i class="fas fa-image ml-2"></i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </form>
    <!-- <Setting :location="'logo/edit'" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import Setting from '@/components/Settings.vue';

export default {
    name : 'editLogo',
    data: function() {
      return{
        imageBase64: null,
        selectedLogo: undefined,
        isEmpty: false,
        logos: [],
        logo: [],
        submitted: false,
        show: false,
        url: utils.getConfig().URL,
      }
    },
    components:{
      Setting,
    },
    methods: {
      getID(name){
        
        let count = 0;
        let inArray = false;
        let id

        this.logos.filter((element)=>{
          if(element.brandname == name){
            inArray = true;
            id = count;
          }
          count++;
        })

        if(inArray)
          return id;
        else  
          return null;
      },
      onFileSelected(event){
        let result;
        this.logo.image = event.target.files[0]

        var reader = new FileReader();
        reader.readAsDataURL(this.logo.image);
        reader.onload =  (e) => {
          result = e.target.result;
          $(".round").attr("src", result)
          this.imageBase64 = result.split(',')[1];
        };
      },
      filter: function(){
        this.submitted = true;
        $(".show").fadeOut(400);

        if(this.getID(this.selectedLogo) == null){
          this.submitted = false;
        }
        else{
          var id_logo = this.getID(this.selectedLogo);
        }

        if(this.submitted){
          this.logo.brandname = this.logos[id_logo].brandname;
          this.logo.image = this.logos[id_logo].image;
          $(".round").attr("src", this.url + 'logo/' + this.logo.image);
          $(".show").fadeIn(400);
          this.show = true;
        }
      },
      handleSubmit(e){

        if(this.imageBase64 != null){

          var dataToInsert = { 
            brandname : this.logo.brandname,
            imageBase64 : this.imageBase64
          }

          const formData = new FormData();
          formData.append('params', JSON.stringify(dataToInsert));

          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            backgroundColor: '#000000',
            color: '#fff',
            opacity: 0.7,
            width: 100,
            height: 100,
          });

          this.$http.post(this.url + 'image/logo/save', formData)
          .then(function(response){
            loader.hide();
            window.location = "/success";
          })
        }
        else{
          window.location = "/success";
        }
      },
    },
    mounted(){
      this.$http.get(this.url + 'image/logo/check')
      .then(function(response){
        const data = response.data;

        data.filter((element)=>{
          const logo = {};

          logo.brandname = element.split('_').pop().split('.')[0];
          logo.image = element;

          this.logos.push(logo);
        })
        
      })
    }
}
</script>


